import { ExerciseApi } from 'fitify-types/src/types/coach-admin-api'
import { BlockedExercisesCollection } from 'fitify-types/src/types/exercise-collection'

import { millisecondsToSerializableTimestamp } from '@/utils/date'

export const updateBlocklistExercisesOptimistic = (
  currentBlockedExercises: BlockedExercisesCollection,
  updateRequest: ExerciseApi.UpdateBlockedExercises.Request
): BlockedExercisesCollection => {
  const newBlockedExercises = Object.entries(
    currentBlockedExercises
  ).reduce<BlockedExercisesCollection>((acc, [id, exercise]) => {
    // Filter unblocked exercises
    if (updateRequest.unblock.some((unblock) => unblock.id === id)) {
      return acc
    }
    return { ...acc, [id]: exercise }
  }, {})

  updateRequest.block.forEach(({ id, blocked_until }) => {
    newBlockedExercises[id] = {
      ...(blocked_until
        ? { blocked_until: millisecondsToSerializableTimestamp(blocked_until) }
        : {}),
    }
  })

  return newBlockedExercises
}

export const revertBlocklistExercisesOptimistic = (
  currentBlockedExercises: BlockedExercisesCollection,
  previousBlockedExercises: BlockedExercisesCollection,
  updateRequest: ExerciseApi.UpdateBlockedExercises.Request
): BlockedExercisesCollection => {
  const newBlockedExercises = Object.entries(
    currentBlockedExercises
  ).reduce<BlockedExercisesCollection>((acc, [id, exercise]) => {
    // Revert block exercise to previous state
    if (updateRequest.block.some((block) => block.id === id)) {
      if (previousBlockedExercises[id]) {
        return { ...acc, [id]: previousBlockedExercises[id] }
      }
      return acc
    }

    return { ...acc, [id]: exercise }
  }, {})

  updateRequest.unblock.forEach(({ id }) => {
    newBlockedExercises[id] = previousBlockedExercises[id]
  })

  return newBlockedExercises
}
