import { User as CommonUser } from 'fitify-types/src/types/user'

export namespace Fitify {
  export const ToastMessageTypeConstants = ['success', 'error', 'info'] as const
  export type ToastMessageType = (typeof ToastMessageTypeConstants)[number]
  export interface ToastMessage {
    message: string
    type: ToastMessageType
    action?: {
      onClick: () => void
      text: string
    }
  }

  // TODO: Use User from fitify-types instead
  export namespace User {
    export interface CustomClaims {
      role: Role
    }

    export enum Role {
      User = 'user',
      Admin = 'admin',
      Coach = 'coach',
    }

    export interface Metadata {
      lastSignInTime: string
      creationTime: string
    }

    export interface ProviderData {
      uid: string
      displayName: string
      email: string
      photoURL: string
      providerId: string
    }
  }

  export interface User {
    uid: string
    email: string
    emailVerified: boolean
    displayName: string
    photoURL: string
    disabled: boolean
    metadata: User.Metadata
    providerData: User.ProviderData[]
    customClaims: User.CustomClaims
    tokensValidAfterTime: string
    details: CommonUser
  }
}
