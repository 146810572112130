export const zIndexes = {
  activityLayoutContent: 1,
  exerciseControls: 4,
  exerciseControlsHovered: 5,
  exerciseStatistics: 2,
  exerciseBlockedOverlay: 3,
  exerciseBlockedLabel: 4,
  exerciseBlocklistGradientOverlay: 2,
  exerciseBlocklistDroppableOverlay: 3,
  slider: 5,
  builderFilterButton: 100,
  selectListDropdown: 100,
  userPanel: 200,
  userPanelPopup: 991,
  toolbar: 666,
  rightSidebarBackdrop: 900,
  rightSidebar: 950,
  builderFilterDragOverlay: 990,
  exerciseDetailsPopup: 991,
  voiceoverOverlay: 990,
  voiceoverPopup: 991,
  exerciseNoteOverlay: 990,
  exerciseNotePopup: 991,
  builderItemDragOverlay: 999,
  modalOverlay: 999,
  overlapButton: 1000,
  tooltip: 1100,
}
