import { setUserId, getAnalytics } from 'firebase/analytics'
import { FirebaseError } from 'firebase/app'
import {
  AuthErrorCodes,
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  type User,
  type UserCredential,
} from 'firebase/auth'
import { useRouter } from 'next/router'
import { createContext, Context, useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import smartlookClient from 'smartlook-client'

import { ROLE_ADMIN, ROLE_COACH } from '@/enums/role'
import { logEvent, AnalyticsEvent } from '@/utils/analytics/firebase/logEvent'

import { useToastMessages } from './useToastMessages'

interface Auth {
  uid: string
  email: string | null
  name: string | null
  photoUrl: string | null
  token: string | null
}

interface AuthContext {
  auth: Auth | null
  loading: boolean
  signIn: (email: string, password: string) => Promise<void>
  logout: () => Promise<void>
}

export const authContext: Context<AuthContext> = createContext<AuthContext>({
  auth: null,
  loading: true,
  signIn: async () => Promise.resolve(undefined),
  logout: async () => Promise.resolve(undefined),
})

const formatAuthState = (user: User): Auth => ({
  uid: user.uid,
  email: user.email,
  name: user.displayName,
  photoUrl: user.photoURL,
  token: null,
})

export function useProvideAuth() {
  const [auth, setAuth] = useState<Auth | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const { showToast } = useToastMessages()
  const router = useRouter()
  const { t } = useTranslation()

  /**
   * Callback function used for firebase.auth.onAuthStateChanged().
   * Takes the user object returned and formats it for my state.
   * We fetch the idToken and append it to my auth state and store it.
   */
  const handleAuthChange = (authState: User | null) => {
    if (!authState) {
      setAuth(null)
      setLoading(false)
      return
    }

    setLoading(true)
    const formattedUser = formatAuthState(authState)

    setAuth(formattedUser)
    setLoading(false)

    setUserId(getAnalytics(), formattedUser.uid)
    if (process.env.NEXT_PUBLIC_SMARTLOOK_API_KEY) {
      smartlookClient.identify(formattedUser.uid, {
        name: formattedUser.name ?? '',
        email: formattedUser.email as string,
      })
    }

    if (!authState) {
      setLoading(false)
      setAuth(null)
    }
  }

  /**
   * Callback for when firebase signOut.
   * Sets auth state to null and loading to false.
   */
  const clear = () => {
    setAuth(null)
    setLoading(false)
  }

  /**
   * Calls firebase signOut and with clear callback to reset state.
   */
  const logout = () => {
    return signOut(getAuth()).then(clear)
  }

  /**
   * Callback function used for response from firebase OAuth.
   * Store user object returned in firestore.
   * @param firebase User Credential
   */
  const signedIn = async (response: UserCredential) => {
    if (!response.user) {
      throw new Error('No User')
    }

    const authedUserClaims = await response.user.getIdTokenResult()

    // check role
    if (
      authedUserClaims.claims.role === ROLE_ADMIN ||
      authedUserClaims.claims.role === ROLE_COACH
    ) {
      setLoading(false)

      if (router.query && router.query.from) {
        logEvent(AnalyticsEvent.Signup)
        router.push(router.query.from as string)
      } else {
        router.push('/users')
      }
    } else {
      showToast({ message: t('login_error'), type: 'error' })
      await logout()
    }
  }

  /**
   * Triggers firebase Oauth for X social network and calls signIn when successful.
   * sets loading to true.
   */
  const signIn = (email: string, password: string) => {
    setLoading(true)

    return signInWithEmailAndPassword(getAuth(), email, password)
      .then(signedIn)
      .catch((error) => {
        let customError = ''
        if (error instanceof FirebaseError) {
          switch (error.code) {
            case AuthErrorCodes.INVALID_PASSWORD:
              customError = 'profile_invalid_passowrd'
              break

            default:
              customError = 'email_login_invalid_credentials_message'
              break
          }
        } else {
          customError = 'login_error'
        }

        showToast({ message: t(customError), type: 'error' })
        setLoading(false)
      })
  }

  /**
   * Watches for state change for firebase auth and calls the handleAuthChange callback
   * on every change.
   */
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), handleAuthChange)
    return () => unsubscribe()
  }, [])

  // returns state values and callbacks for signIn and signOut.
  return {
    auth,
    loading,
    signIn,
    logout,
  }
}

export function AuthProvider({ children }: any) {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export const useAuth = () => useContext(authContext)
