import { getAuth } from 'firebase/auth'
import React, { ForwardedRef } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import { getErrorHandler } from '@/utils/errorHandler'

import ErrorFallback from './ErrorFallback'

/**
 * HOC for wrapping components with ErrorBoundary
 */
function withErrorBoundary<P extends object>(
  WrappedComponent: React.ComponentType<P>
) {
  type RefType = React.ElementRef<typeof WrappedComponent>
  const firebaseAuth = getAuth()

  const WithErrorBoundary = React.forwardRef<RefType, P>(
    (props, ref: ForwardedRef<RefType>) => {
      const onErrorHandler = (error: Error) => {
        if (process.env.NODE_ENV !== 'development') {
          if (firebaseAuth.currentUser) {
            getErrorHandler()?.setUser(firebaseAuth.currentUser.uid)
          }
          getErrorHandler()?.report(error)
        }
      }

      return (
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onError={onErrorHandler}
        >
          <WrappedComponent ref={ref} {...(props as P)} />
        </ErrorBoundary>
      )
    }
  )

  WithErrorBoundary.displayName = `WithErrorBoundary(${
    WrappedComponent.displayName || WrappedComponent.name || 'Component'
  })`

  return WithErrorBoundary
}

export default withErrorBoundary
