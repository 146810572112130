export const omit = <T extends object, K extends keyof T>(
  obj: T,
  keys: K[]
): Omit<T, K> => {
  const copy = { ...obj }
  keys.forEach((key) => {
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete copy[key]
  })
  return copy
}

export const hasValue = (value: unknown): boolean => {
  if (value === undefined || value === null) {
    return false
  }
  if (typeof value === 'number') {
    return !isNaN(value)
  }
  if (value === true || value === false) {
    return true
  }
  if (typeof value !== 'object') {
    return Boolean(value)
  }
  if (Array.isArray(value)) {
    return value.some(hasValue)
  }
  return Object.values(value).some(hasValue)
}
