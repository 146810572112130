import { Timestamp } from 'firebase/firestore'
import { isSerializableTimestamp } from 'fitify-types/src/type-guards/common'
import {
  FirestoreTimestamp,
  SerializableTimestamp,
} from 'fitify-types/src/types/common'
import { DateTime, Duration, type StringUnitLength } from 'luxon'

// TODO: Extend date format and use it across date functions
export enum DateFormat {
  Day = 'dd',
  DayMonth = 'd\u00a0MMM',
  DayMonthYear = 'd\u00a0MMM\u00a0yyyy',
  DayShort = 'd',
  FullDateAndTime = 'yyyy-MM-dd h:mm\u00a0a',
  FullDateAndTimeWithZone = 'yyyy-MM-dd h:mm\u00a0a z',
  IsoDate = 'yyyy-MM-dd',
  IsoYearMonth = 'yyyy-MM',
  MessageWeekDayDateAndTime = 'EEE, d\u00a0LLL, hh:mm\u00a0a',
  MonthDay = 'MMM\u00a0d',
  MonthDayYear = 'MMM\u00a0d yyyy',
  MonthDayYearShort = 'MM/dd/yyyy',
  Time = 'h:mm\u00a0a',
  TimeZoneLong = 'ZZZZZ',
  WorkoutDay = 'cccc MM/dd',
  WorkoutDayLong = 'cccc, MMMM\u00a0d',
}

export const timestampToDate = (
  timestamp: number,
  format: DateFormat = DateFormat.FullDateAndTime,
  targetTimezone?: string
) => {
  if (timestamp === null || timestamp === undefined) {
    return '-'
  }
  const date = new Date(timestamp * 1000)
  return DateTime.fromJSDate(date, { zone: targetTimezone }).toFormat(format)
}

export const formatSleepDataDuration = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)

  return `${hours}h ${minutes}m`
}

export const formatDate = (
  dateInput: string,
  format: DateFormat = DateFormat.IsoDate
) => {
  const date = new Date(dateInput)
  return DateTime.fromJSDate(date).toFormat(format)
}

export const formatDateId = (
  dateInput: string,
  format: DateFormat = DateFormat.IsoDate
) => {
  return DateTime.fromFormat(dateInput, DateFormat.IsoDate).toFormat(format)
}

export const formatDateTime = (
  dateInput: string | Date,
  format: DateFormat = DateFormat.FullDateAndTime,
  isUTC?: boolean
) => {
  const date = new Date(dateInput)
  if (isUTC) {
    return DateTime.fromJSDate(date).toUTC().toFormat(format)
  } else {
    return DateTime.fromJSDate(date).toFormat(format)
  }
}

export const parseDateStringToDate = (dateInput: string) => {
  const date = new Date(dateInput)
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
}

export const formatWorkoutDayFromString = (
  dateInput: string,
  format: DateFormat = DateFormat.WorkoutDay
) => {
  const date = parseDateStringToDate(dateInput)
  return DateTime.fromJSDate(date).toFormat(format)
}

export const createFirebaseTimestamp = (
  timestamp?: FirestoreTimestamp
): Timestamp | null => {
  return timestamp && isSerializableTimestamp(timestamp)
    ? new Timestamp(timestamp?._seconds, timestamp?._nanoseconds)
    : null
}

export const formatTimestamp = (
  value?: FirestoreTimestamp,
  format: DateFormat = DateFormat.FullDateAndTime,
  targetTimezone: string | undefined = undefined
): string | null => {
  const timestamp = createFirebaseTimestamp(value)

  if (timestamp) {
    const date = timestamp.toDate()
    return DateTime.fromJSDate(date, { zone: targetTimezone }).toFormat(format)
  }

  return null
}

export const formatWorkoutDayFromTimestamp = (
  value?: FirestoreTimestamp,
  targetTimezone?: string
) => {
  return formatTimestamp(value, DateFormat.WorkoutDay, targetTimezone)
}

/**
 * Formats the duration in seconds to hh:mm:ss format, or mm:ss when shorter than 1 hour.
 */
export const secondsToTimeFormat = (
  seconds: number,
  withPaddedMinutes = true
) => {
  const luxonDuration = Duration.fromMillis(seconds * 1000)
  const format =
    luxonDuration.as('hours') >= 1
      ? 'hh:mm:ss'
      : `${withPaddedMinutes ? 'mm' : 'm'}:ss`
  return luxonDuration.toFormat(format)
}

export const timeAgo = (date: string, style: StringUnitLength = 'long') => {
  return DateTime.fromJSDate(new Date(date)).toRelative({
    locale: 'en-US',
    style,
  })
}

export const getYesterday = () => {
  const yesterday = new Date()
  yesterday.setHours(23, 59, 59)
  yesterday.setHours(yesterday.getHours() - 24)

  return yesterday
}

export const getZonedDatetimeFromISO = (date: string, timezone?: string) => {
  return DateTime.fromISO(date, {
    zone: timezone,
  })
}

export const getDateFromString = (inputDay: string): Date => {
  const splitDate = inputDay.split('-')

  const year = parseInt(splitDate[0])
  const month = parseInt(splitDate[1]) - 1
  const day = parseInt(splitDate[2])

  return new Date(year, month, day)
}

export const getDatesWithinRange = (
  firstDateFormatted: string,
  lastDateFormatted: string
) => {
  const days: string[] = []
  const firstDate = getDateFromString(firstDateFormatted)
  const lastDate = getDateFromString(lastDateFormatted)
  let tempFirstDate: Date
  let tempLastDate: Date

  if (firstDate > lastDate) {
    tempFirstDate = lastDate
    tempLastDate = firstDate
  } else {
    tempFirstDate = firstDate
    tempLastDate = lastDate
  }

  for (
    let dt = new Date(tempFirstDate);
    dt <= new Date(tempLastDate);
    dt.setDate(dt.getDate() + 1)
  ) {
    days.push(formatDate(new Date(dt).toString()))
  }

  return days
}

/**
 * Compares two dates and returns true if so.
 * @param date1 The first date to compare.
 * @param date2 The second date to compare.
 * @returns Returns true if the dates are equal
 */
export const compareDates = (date1: Date, date2: Date) => {
  return date1.getTime() === date2.getTime()
}

/**
 * Returns true if the date is in the format yyyy-MM-dd.
 * @param date The date to check.
 * @returns Returns true if the date is in the format yyyy-MM-dd.
 * @example
 * isInDateFormat('2021-01-01') // true
 * isInDateFormat('2021-01') // false
 */
export const isInDateFormat = (date: string): boolean => {
  const regexFullDateInFormatYYYYMMDD = /^\d{4}-\d{2}-\d{2}$/
  return regexFullDateInFormatYYYYMMDD.test(date)
}

export const millisecondsToSerializableTimestamp = (
  milliseconds: number
): SerializableTimestamp => {
  return {
    _seconds: Math.floor(milliseconds / 1000),
    _nanoseconds: (milliseconds % 1000) * 1000000,
  }
}
