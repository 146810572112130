import { getAnalytics, logEvent as logFirebaseEvent } from 'firebase/analytics'

export enum AnalyticsEvent {
  ExerciseFeedbackHover = 'exercise_feedback_hover',
  ExerciseHistoryOpen = 'exercise_history_open',
  SequenceGenerate = 'sequence_generate',
  SubscriptionTransfer = 'subscription_transfer',
  Signup = 'signup',
}

export const logEvent = (
  name: AnalyticsEvent,
  params?: Record<string, unknown>
) => {
  logFirebaseEvent(getAnalytics(), name, params)
}
